import React, { useState } from "react"

import Image from "../components/image"
import SEO from "../components/seo";

import "./../styles/index.scss";

const IndexPage = () => {

    let [currentPage, setCurrentPage] = useState("Inicio");

    return (
        <div className="index">

            <section className="indexs">
                <h1>Sitio web en construcción</h1>
            </section>


        </div>
    )
}

export default IndexPage
